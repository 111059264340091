.bio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

  }
  
  .image-container {
    position: relative;
    top: 60px;
    width: 100%;
    border: 1px solid black;
    max-width: 400px; 
    margin: 15px;
   
  }
  
  .responsive-image {
    width: 100%;
    height: auto;
  }
  
  .download-button {
    position: absolute;
   top: 40%;
    height: auto;
    right: 60px;
    
    transform: translateX(-50%);
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .download-button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .image-container {
      max-width: 90%;
    }
  }
  