/* services  design */

.flex-direction {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.card-services {
  margin: 5px 10px;
  padding-top: 5px;
  background-image: linear-gradient(
    to right,
    rgb(159, 205, 215),
    rgb(251, 249, 249),
    rgb(159, 205, 215)
  );
  min-width: 30%;
  height: 16rem;
  border-radius: 28px;
  border: 2px solid white;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}

.text-center {
  font-weight: bold;
}

.card-services img {
  display: block;
  border-radius: 50%;
  margin: auto;
  width: 11rem;
  height: 10rem;
  border: 2px solid white;
  transform: scale(0.9);
}
.card-services img:hover {
  transform: scale(1);
}
.btn-bg {
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  min-width: 34%;
  /* margin-top: 8%; */
  border-radius: 29px;
  margin-left: 34%;
  cursor: pointer;
  position: relative;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}

.btn-bg:hover {
  color: white;
  background-color: rgb(34, 20, 100);
}

/* services responsive  */
@media only screen and (max-width: 992px) {
  .card-services {
    width: 80%;
    height: 25%;
  }

  .flex-direction {
    flex-direction: column;
  }

  .card-services {
    height: 15rem;
  }

  .card-services {
    background-color: #94dcd7 !important;
  }

  .btn-bg {
    width: 35%;
    margin-left: 34%;
    margin-top: 2%;
  }
}

/* ticker css and animation */
* {
  font-family: Play;
}

p {
  font-size: 20px;
  color: #444;
}

/***** Global Slide *****/
.slide-right,
.slide-left {
  width: 100%;
}

/***** Slide Right *****/
.slide-right {
  animation: 8s slide-right infinite;
}

@keyframes slide-right {
  from {
    margin-left: -100%;
  }

  to {
    margin-left: 40%;
  }
}

/***** Slide Left *****/
.slide-left {
  animation: 8s slide-left infinite;
}

@keyframes slide-left {
  from {
    margin-left: 100%;
  }

  to {
    margin-left: 35%;
  }
}

/***** Misc Styles *****/
.first-animation-flow {
  width: 90%;

  overflow: hidden;

  text-align: center;
}

/***** FadeIn Underline *****/
.line {
  border-bottom: 3px solid red;
  width: 400px;
  margin: auto;
  margin-left: 38%;
}

.fade-in {
  animation: fadeIn ease 3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 980px) {
  /* ticker css and animation */

  * {
    font-family: Play;
  }

  p {
    font-size: 20px;
    color: #444;
  }

  /***** Global Slide *****/
  .slide-right,
  .slide-left {
    width: 100%;
  }

  /***** Slide Right *****/
  .slide-right {
    animation: 8s slide-right infinite;
  }


  @keyframes slide-right {
    from {
      margin-left: -100%;
    }

    to {
      margin-left: 0%;
    }
  
  }

  /***** Slide Left *****/
  .slide-left {
    animation: 8s slide-left infinite;
  }

  @keyframes slide-left {
    from {
      margin-left: 100%;
    }

    to {
      margin-left: 0%;
    }
  }

  /***** Misc Styles *****/
  .first-animation-flow {
    width: 90%;
    margin: auto;

    overflow: hidden;

    margin-top: 30px;

    text-align: center;
  }

  /***** FadeIn Underline *****/
  .line {
    border-bottom: 3px solid red;
    width: 200px;
    margin: auto;
  }

  .fade-in {
    animation: fadeIn ease 3s;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @media only screen and (max-width: 980px) {
    .first-animation-flow {
      width: 350px;
    }
  }
}

.swal-title {
  color: blue;
}

.swal-modal {
  border-radius: 20px;
  box-shadow: 2px 2px 5px 8px rgb(64, 63, 63);
  border: none;
}

@media only screen and (min-width: 980px) {
  .swal-modal {
    width: 900px;
    border-radius: 20px;
    box-shadow: 2px 2px 5px 8px rgb(64, 63, 63);
    border: none;
  }

  .swal-text {
    font-size: 30px;
    text-align: justify;
    color: black !important;
  }

  .swal-title {
    font-size: 40px;
    width: 433px;
    margin: auto;
    color: blue;
    border-bottom: 4px solid rgb(255, 0, 0);
    margin-top: 20px;
  }
}

/* ************************************card slider**************************************** */

/* card slider */

.space {
  width: 100%;
  height: 5rem;
}

.divcard {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: space-around;
}

.cardfirst {
  width: 40%;
  border-radius: 29px;
  padding: 10px;
  margin: 5px;
  background-color: #5b5472;
  /* background-color: hsl(254, 84%, 62%); */
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* min-height: 82vh; */
}

.cardfirst h4 {
  margin-top: 1rem;
  color: white;
}

.cardimage {
  height: 200px;
  width: 200px;
  margin-top: 1rem;
}

.cardimage img {
  height: 200px;
  width: 200px;
  object-fit: cover;
  border: 2px solid white;
  border-radius: 100%;
}

.pra {
  padding-top: 15px;
  margin: 20px;
  height: 55vh;
  background-color: white;
  border-radius: 10px;
}
.pra p {
  padding: 0px !important;
  word-spacing: -2px;
  padding-top: 10px;
  font-size: 21px;
  font-weight: 500;


}
p {
  padding: 10px;
}
.btn {
  width: 150px;
  border-radius: 10px;
  background-color: #72c6d2;
  color: rgb(52, 50, 50);
}
/* .cardsecond {

  width: 40%;
  border-radius: 29px;

  background-color: #4b4367;
  display: flex;
  align-items: center;

  flex-direction: column;
  min-height: 80vh;
} */

/* .cardsecond h4 {
  margin-top: 1rem;
  color: white;
} */
/* .cardsecond p {
min-height: 200px;
} */
/* .btnup{

  margin-top: 80px;
margin-bottom: 10px;
  position: relative!important;
} */
.button {
 
  top: -10px;
  position: relative;
}


@media only screen and (max-width: 1180px) {
  .divcard {
    margin: 0px 10px;
    /* flex-wrap: wrap; */
    flex-direction: column;
  }
  .btnup {
    margin-top: 0px;
    margin-bottom: 0px;
    position: relative !important;
  }
  .cardfirst {
    margin: 10px;
    width: 95%;
    min-height: 300px;
  }
.pra{
  height: auto;
}
.pra p{
  font-size: medium;
}

  /* .cardsecond {
    margin: auto;
    width: 95%;
    margin-top: 1rem;
    min-height: 300px;
  } */

  .first-animation-flow {
    margin-top: 20px;
    margin-bottom: 0px;
  }
}
