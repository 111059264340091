*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
.main-container-contact{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
flex-wrap: wrap-reverse;
}
.address-container{
    display: flex;
    flex-wrap: wrap-reverse;
    /* align-items: baseline; */
    justify-content: space-between;
    width: 700px;
box-shadow:
        inset 0 -3em 3em rgba(0, 0, 0, 0.1),
        0 0 0 2px rgb(255, 255, 255),
        0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
        min-height: 380px;
}
.address-information{
    width: 320px;
    margin-top: 3%;
  min-height:300px;
    margin: auto;
}
.address-information strong{
    font-size: 30px;
    text-align: center;
    display: block;
    margin-top: 12%;

}
.address-information p{
    text-align: center;
    padding: 0px;
}
.address-information p span{
    font-size: 20px;
    margin: 0px 5px;

}
.map-address{
    display: flex;
    flex-direction: column;
align-items: center;
flex-wrap: wrap-reverse;
padding: 5px;
margin: auto;
}
.map-address strong{
    font-size: 30px;
    text-align: center;
    display: block;
  
}
.map-address p{
    text-align: center;
  
}
.map-address span{
    text-align: center;
    font-size: 35px;
    margin: 0px 10px;


}
/* ********************* contact form css  ******************************************************************************/
.enquiry-container{
    width: 400px;
    min-height: 380px;
cursor: pointer;
box-shadow:
        inset 0 -3em 3em rgba(0, 0, 0, 0.1),
        0 0 0 2px rgb(255, 255, 255),
        0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}
.enquiry-container:hover {
    border-radius: 29px;
   border: 2px solid cyan;
    box-shadow:
        inset 0 -3em 3em rgba(13, 210, 236, 0.1),
        0 0 0 2px rgb(255, 255, 255),
        0.3em 0.3em 1em rgba(39, 174, 184, 0.3);
}
.enquiry-container strong{
    font-size: 30px;
    text-align: center;
    display: block;
}
.enquiry-container form div{
    text-align: center;
}
.enquiry-container label{
    font-size: 20px;
}
.enquiry-container input{
margin: 10px 8px;
border-radius: 5px;
border: 1px solid rgb(172, 165, 165);
font-size: 16px;
padding: 5px;
padding-left: 10px;
color: rgb(67, 65, 65);
width: 50%;
/* margin-left: 25px; */
}

.feedback{
    margin-top: 2%;
}
.feedback label{   
     position: relative;
    top: -1.1rem;
    left: -9px;

}
.feedback textarea{
    border-radius: 5px;
    height: 40px;
    width: 50%;
 

}
.enquiry-container input[ type=submit]{
    display: block;
   margin: auto;
   width: 120px;
   font-size: 17px;
   background-color: cyan;
font-weight: 700;
margin-top: 5%;
margin-bottom: 1rem;

}
.plink p{
    color: blue;
}


 /**************************** responsive code****************************** **/
 @media only screen and (max-width: 500px){


 .enquiry-container input[ type=submit] {
    
             box-shadow: 10px 10px 8px #a6e3e5;
    
         }
    
         .enquiry-container:hover {
        box-shadow:none!important;
               
         }
                  }