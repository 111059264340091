* {
  margin: 0;
  padding: 0;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  background: white;
}

body::-webkit-scrollbar-thumb {
  background: rgb(4, 123, 251);
  border: 1px solid blue;
  border-radius: 30px;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.appointment {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  width: 500px;
  margin: 0 auto;
  padding: 10px;
  margin-top: 90px;
  border-radius: 5px;
  background-color: #e2edee;
}

.form {
  display: grid;
  gap: 5px;
  padding: 25px;
  background-color: #4c9ef0;
}

.form input,
.form select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.form .select {
  position: relative;
}

.form .select:after {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.form label {
  font-weight: bold;
  grid-column: 1 / 2;
}

.form .button {
  padding: 10px;
  margin: 10px;
  width: 50%;
  margin-left: 70px;
  margin-top: 20px;
  background-color: #23e9e9;
  color: #000000;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.form .button:hover {
  background-color: #007bff;
  color: #fff;
}
.status {
  display: none;
}

.box:hover {
  background-color: #2b91f7;
}

.appointmentfields {
  padding: 10px 15px;
  background-color: #6f94eb;
  color: #fff;
  border: none;
  border-radius: 10px;
  margin: 10px;
}

.appointmentfields:hover {
  background-color: #80e2b9;
  color: rgb(83, 84, 85);
}

.appointmentfields::placeholder {
  color: white;
  opacity: 1;
}

@media screen and (max-width: 480px) {
  .box {
    padding: 10px;
    margin-top: 55px;
  }

  .form input,
  .form select {
    padding: 8px;
  }
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form1 {
  width: 400px;
  padding: 30px;
  background-color: #6592f4;
  border-radius: 8px;
  box-shadow: 3px 7px 10px rgba(0, 0, 0, 0.1);
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.email1,
.password {
  width: 100%;
  padding: 10px;
  margin-bottom: 50px;
  border: none;
  border-radius: 4px;
}

.btn,
.login_btn {
  width: 40%;
  padding: 10px;
  background-color: #1858e3;
  font-weight: bold;
  border-radius: 4px;
}

.login_btn {
  background-color: #2b03f2;
}

@media screen and (max-width: 768px) {
  .form1 {
    width: 90%;
  }
}

.site {
  padding: 80px;
}

.btn-container {
  display: flex;
  gap: 10px;
}

.search-container {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.btn1,
.btn3 {
  padding: 10px 15px;
  background-color: #6f94eb;
  color: #fff;
  border: none;
  border-radius: 10px;
  margin: 2px;
  position: relative;
  top: 1px;
}

.btn1:hover,
.btn3:hover {
  background-color: #80ace2;
  color: rgb(85, 83, 83);
}

.btn2 {
  display: flex;
  gap: 10px;
}

@media (max-width: 768px) {
  .site {
    padding: 40px;
  }

  .btn-container,
  .search-container {
    flex-direction: column;
    align-items: center;
  }

  .btn1,
  .btn3 {
    width: 100%;
    margin: 10px 0;
    padding: 15px 20px;
    font-size: 16px;
  }

  input[type="date"],
  input[type="text"] {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .btn2 {
    text-align: center;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.btn4 {
  background-color: #fff;
  color: #333;
  border: 1px solid #333;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
}

.btn4:hover {
  background-color: #333;
  color: #fff;
}

.btn4.active {
  background-color: #333;
  color: #fff;
}

@media (max-width: 768px) {
  .pagination {
    flex-wrap: wrap;
  }

  .btn4 {
    margin: 5px;
  }
}
