@import url("https://fonts.googleapis.com/css2?family=Domine:wght@700&display=swap");

* {
  margin: 0%;
  padding: 0%;
  list-style: none;
  text-decoration: none;
  list-style: none;
}

.zindex {
  display: flex;
  z-index: 5 !important;
}

.alert span {
  position: fixed;
  color: aliceblue;
  font-size: 20px;
}

.navbar {
  width: 100%;
  position: fixed;
  z-index: 2;
  display: flex;

  justify-content: space-between;
  /* background-color: rgb(34, 20, 100); */
  background-color: rgb(61, 133, 193);
}

.logo img {
  width: 100%;
  height: 50px;
  border-radius: 50%;
}

.vcr {
  display: flex !important;
}

.sm {
  display: flex;
  justify-content: center;
  width: 20%;
  padding: 0px !important;
}

.sm li a:hover {
  transform: scale(1.2);
}

.sm li a:hover::before {
  width: 0%;
}

.navbar ul {
  display: flex;
  margin-bottom: 0 !important;
  gap: 5px;
}

.spaninlined {
  display: none;
}

.navbar ul li a {
  font-size: 1.5rem;
  text-decoration: none;
  color: white;
  font-weight: 600;
  padding: 0px 10px;
}

.navbar a {
  background-image: linear-gradient(
    to right,
    rgb(8, 243, 243),
    white 50%,
    white 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  position: relative;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

a:before {
  content: "";
  background: white;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

a:hover {
  background-position: 0;
}

.active {
  border-bottom: 2px solid white;
  border-radius: 20px;
}

.btn-book {
  font-size: large;
  padding: 5px;
  margin: 18px;
  color: aliceblue;
  border-radius: 10px;
  background-color: rgb(56, 60, 167);
  border: 1px solid white;
}
.btn-book:hover {
  background-color: rgb(209, 229, 223);
  color: black;
}
.book-appointment {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.book-appointment a {
  display: inline-block;
  padding: 10px 20px;
  background-color: #3498db;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.book-appointment a:hover {
  background-color: #2980b9;
}

.burger {
  display: none;
  position: absolute;
  cursor: pointer;
  right: 8px;
  top: 10px;
  float: right;
}

.phone {
  margin-left: 18rem;
  display: none;
  position: fixed;
}

.goog-logo-link,
.goog-logo-link:link,
.goog-logo-link:visited,
.goog-logo-link:hover,
.goog-logo-link:active {
  display: none;
}

.goog-te-gadget {
  font-size: 0px;
}
.goog-te-gadget .goog-te-combo {
  color: blue;
}

.goog-te-gadget .goog-te-combo {
  width: 80%;
}

/* responsive navbar css  */
@media only screen and (max-width: 780px) {
  .navbar {
    overflow: hidden;
    height: 50px;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .nav {
    height: 350px;
  }
  .navbar ul {
    box-sizing: border-box;
    display: none;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-start;
  }

  .sm li {
    margin: 10px 0px;
  }
  .burger {
    color: white;
    display: block;
  }

  .spaninlined {
    margin-top: 0px !important;
    margin-right: 35px;
    font-size: 23px;
    text-align: center;
    color: white;
    font-weight: bold !important;
    font-family: "Slabo 27px", serif;
  }

  .navbar ul li a {
    font-size: 22px;
    float: left;
    padding: 0px 10px;
  }

  .logo img {
    display: none;
  }

  .divbar h1 {
    margin: 6rem 8rem;
  }

  .alert {
    display: none;
  }
  .hidephoneicon {
    display: flex;
  }

  .phone {
    margin-top: 150%;
    box-shadow: 2px 2px 0px 2px gray;
    margin-left: 82%;

    display: flex;
    width: 4rem;
    height: 4rem;
    border-radius: 100%;
    background-color: rgb(34, 20, 100) !important;
  }

  .phone li {
    /* background-color: brown; */
    margin-top: 15px;
    margin-left: 7px;
  }
  .hide {
    display: block !important;
  }

  #google_translate_element {
    margin-left: -45px;
  }
}
