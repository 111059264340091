* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: rgb(248, 243, 243) !important;

}
.carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
  filter: invert(-100) !important;
  
 
}
.container-height {
  width: 85%;
  margin: auto;
  border-radius: 50px;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}

.carousel-item img {
  height: 470px;
  border-radius: 29px;
  /* margin-top: 10px; */
  /* object-fit: contain; */
}
.div-item-component {
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 5%;
}
.div-item-componented {
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  flex-wrap: wrap;
  display: none;
  margin-top: 15%;
}
.color {
  margin: 1rem 5px;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
  width: 300px;
  height: 16rem;
  border-radius: 28px;
}
.btn-design {
  position: relative;
  top: 200px;
  display: block;
  margin: auto;

  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  min-width: 30%;

  border-radius: 29px;

  cursor: pointer;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}
.btn-seconsed {
  min-width: 30%;
  font-family: "Quicksand", sans-serif;
  font-weight: 900;
  border-radius: 29px;
  cursor: pointer;
  background-image: linear-gradient(
    to right,
    rgb(159, 205, 215),
    rgb(251, 249, 249),
    rgb(159, 205, 215)
  );
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}
.btn-design:hover {
  color: white;
  background-color: rgb(34, 20, 100);
}
.bg-first {
  background-image: url("/public/assets/images/front.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-Seconded {
  background-image: url("/public/assets/images/LW_1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-third {
  background-image: url("/public/assets/images/Private\ Ward.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (max-width: 900px) {
  .div-item-component {
    display: none;
  }
  .div-item-componented {
    display: flex;
  }

  .container-height {
    margin-top: 5%;
  }
  .carousel-item img {
    height: 400px;
  }
}
