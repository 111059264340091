/* main in hospital */
.divbar {
  /* background-image: url("/public/assets/images/DocImg.avif"); */
  background-image: url("/public/assets/images/doc4.webp");
  background-position: top center;
  background-repeat: no-repeat;
  overflow: hidden;
  background-size: cover;
  height: 500px;
  /* height: 70vh; */
  z-index: 1;
}
/* url("/public/assets/images/images.jpg"); */
.div-p p {
  font-size: 21px;
  font-family: "Slabo 27px", serif;
  padding: 5px 25px;
  text-align: justify;
}

.div-p li {
  font-size: 19px;
  font-family: "Hind Madurai", sans-serif;
  margin-left: 2%;
}

.div-p li a:hover {
  color: red !important;
}

.divbar h1 {
  margin: 9rem 8rem;
  display: inline-block;
  /* font-family: 'Quicksand', sans-serif; */
  font-size: 3rem;
  color: rgb(98, 93, 93);
  font-weight: bolder;
}

.divbar h5 {
  margin: -8rem 4rem;
  display: block;
  font-size: 2.3rem;
  font-style: italic;
  /* word-spacing: 1px; */
  color: #0a245d;
}
.services {
  margin-top: 18px;
}

.j {
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: blue; */
}

.j h1 {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bold;
  font-style: italic;
  color: blue;
  font-size: 3rem;
}

.j h1 li a {
  text-decoration: none;
  list-style: none;

  color: brown;
}

.button-container {
  display: flex;
  /* justify-content: center; */
  gap: 10px; 
  margin-top: 20px;
  flex-wrap: wrap;
}

.btn-divbar {
  color: white;
  cursor: pointer;
  position: relative;
  left: 10rem;
  top: 10rem;
  background-color: #3d65be;
  border: none;
  font-size: 22px;
  border-radius: 10px;
  padding: 10px;
}

.btn-divbar2 {
  position: relative;
  left: 10rem;
  top: 10rem;
  font-size: 22px;
  background-color: #AA0144;
}
.btn-divbar:hover {
  background-color: #59c6ee;
}

.flex {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.card1 {
  font-family: "Domine", serif;
  font-weight: bold;
  background-image: linear-gradient(
    to right,
    rgb(159, 205, 215),
    rgb(251, 249, 249),
    rgb(159, 205, 215)
  );
  margin: 2px 10px;
  width: 19rem;
  height: 15rem;
  border-radius: 28px;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}

.card1 img {
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
  display: block;
  border-radius: 50%;
  margin: auto;
  width: 11rem;
  height: 10rem;
  margin-top: 5px;
  transform: scale(0.9);
}
.card1 img:hover {
  transform: scale(1);
}

/* responsive main  */
@media only screen and (max-width: 841px) {
  .divbar {
    width: 100%;
    height: 42vh;
  }

  .div-p p {
    font-size: 17px;
  }

  .div-p li {
    margin-left: 7%;
  }

  .alert {
    display: none;
  }

  .divbar h1 {
    margin: 6rem 0.8rem !important;
    text-align: center;
    font-size: 1.2rem;
  }

  .divbar h5 {
    margin: -5.5rem 1rem;
    text-align: left;
    font-size: 1rem;
    font-size: 13.5px;
    font-style: italic;
  }

  .services {
    margin-top: 10px;
  }

  .btn-divbar {
    position: absolute;
    left: 0.5rem;
    border: none;
    font-size: 14px;
    border-radius: 25px;
    padding: 8px;
    width: 170px;
    margin-top:10px;
  }
  .btn-divbar2 {
    position: absolute;
    width: 170px;
    left: 0.5rem;
    margin-top: 70px;
    border: none;
    font-size: 14px;
    border-radius: 25px;
    padding: 8px;
  }
  .flex {
    margin-top: 10px;
    flex-direction: column;
    justify-content: space-between;

  }

  .card1 {
    margin: 10px;
  }
  
}



