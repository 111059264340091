/* footer css */
footer {
  min-height: 280px;
  background-color: rgb(43, 72, 108);
  margin-top: 100px;
}

.i h6 a {
  color: aquamarine;
  text-decoration: none;
}

.footer {
  border-top: 2px solid black;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.diva p a:hover {
  color: rgb(18, 107, 185);
}

.divb h3 {
  margin-top: 2px !important;
  color: rgb(8, 243, 243);
}

.divb li {
  color: white;
  font-weight: bold;
  font-size: 20px;
}
.divb li a {
  color: rgb(8, 243, 243);
  text-decoration: none;
}

.divb h3 {
  margin-top: -16px;
}

.divb h3 span {
  color: black;
}

.divba {
  margin-left: 4rem;
}

.divba li {
  color: white;
  white-space: pre;
  font-size: 18px;

  font-family: "Slabo 27px", serif;
}

.i {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: rgb(15, 52, 88);
}

.i h6 {
  font-size: 18px !important;
  color: white;
}
.divd {
  margin-top: 20px;
}

.divd h3 {
  margin-top: 3px;
  margin-left: 52px;
  color: rgb(8, 243, 243);
  display: -webkit-box;
}

/* footer responsive*/
@media only screen and (max-width: 850px) {
  .footer {
    overflow: hidden !important;
    flex-direction: column;
  }

  #gap {
    margin-top: 10px !important;
  }
  p {
    font-size: 17px;
    font-family: "Slabo 27px", serif;
    padding: 5px 12px;
    text-align: justify;
  }

  #space {
    line-height: inherit !important;
  }

  .alert {
    display: none;
  }

  .divb {
    margin-left: 5%;
  }

  .divb li {
    font-weight: bold;
    font-weight: 900px !important;
  }

  .divba h5 {
    margin-left: 20%;
    margin-top: 2%;
    font-size: 15px !important;
  }

  .i h6 {
    font-size: 15px !important;
  }

  .i {
    max-width: 100%;
  }

  .divd {
    margin-left: -20%;
  }

  .divb li {
    font-size: 15px;
  }

  .divba li {
    font-size: 15px;
  }

  footer {
    min-height: 28rem;
  }
}
