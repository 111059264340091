.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.popup-content {
  background-color: #4c57d2;
  color: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 10px;
  width: 650px;
  text-align: center;
  position: relative;
}

.popup-content h2 {
  margin-bottom: 20px;
  color: white;
}

.popup-content p,
.popup-content ul {
  text-align: left;
  color: white;
  font-size: 20px;
  font-weight: 500;
}

.popup-content li {
  text-align: left;
  color: white;
  font-size: 20px;
  font-weight: 500;
}

.popup-content hr {
  height: 5px;
  width: 100%;
  background-color: white;
}

.popup-content p {
  background-color: white;
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: 500;
}

.btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgb(251, 251, 251);
  color: rgb(0, 0, 0);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-close:hover {
  background-color: lightgray;
}


@media only screen and (max-width: 841px) {
  .popup-content {
    width: 80%;
    padding: 15px;
  }

  .popup-content h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }

  .popup-content p,
  .popup-content li {
    font-size: 18px;
    font-weight: 400;
  }

  .popup-content hr {
    height: 3px;
  }

  .btn-close {
    padding: 8px 15px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 480px) {
  .popup-content {
    width: 90%; 
    padding: 10px;
  }

  .popup-content h2 {
    font-size: 1.3rem; 
  }

  .popup-content p,
  .popup-content li {
    font-size: 16px;
  }

  .btn-close {
    padding: 6px 12px; 
    font-size: 12px; 
  }
}
