@import url('https://fonts.googleapis.com/css2?family=Beau+Rivage&family=Quicksand:wght@300&family=Zen+Loop&display=swap');
*{
    margin: 0px;
    padding: 0px;
    list-style: none;
    text-decoration: none!important;

}
.space {

    width: 100%;
    height: 0rem;
}

p {
    text-align: justify;
 
    margin:0px 10px;
}

.aboutA {

    display: flex;
    flex-direction: column;
    justify-content: space-between;
   
}

.aboutA h3 {
  
    font-weight: 700;
   
 text-align: center;
}

.aboutA p img {
    float: left;
    margin: 0px 19px 10px 0px;
    object-fit: cover;
    width: 25rem;
    max-height: 18rem;
    border-radius: 29px;
    box-shadow:
    inset 0 -3em 3em rgba(0,0,0,0.1),
          0 0  0 2px rgb(255,255,255),
          0.3em 0.3em 1em rgba(0,0,0,0.3);
          transform: scale(0.9);     
}
.aboutA p img:hover{
    transform: scale(1);  
}
hr{
    border-top: 1px solid blueviolet;
}


@media only screen and (max-width: 841px) {
    .aboutA p img {
        width: 20.5rem;
        margin: 10px auto;
      
      

    }
    .aboutA p img {
        margin: 5px 19px 10px 0px;

    }
    .space {
        width: 100%;
        height: 6rem;
    }
}

@media only screen and (max-width: 480px) {
    .aboutA p img {
        width: 100%;
    }
}
/* ***************************************about component***************************************************  */
.card-slider-animation {
    margin-top: 2rem;
    /* border: 10px solid red!important; */
    align-items: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
   
    min-height: 300px;

  
}
#testmonial-css{
 
    font-size: 30px;
    text-align: center;
    padding-bottom: 10px;
    font-weight: 900;

}
.center{
    text-align: center;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-456px * 2));
    }
}

.quotes-famous {
    min-height: 300px;
    overflow: hidden;
    position: relative;
    border-radius: 29px;
    width: 90vw;
     margin: auto;

}

.quotes-famous::before,
.quotes-famous::after {
   
    content: "";
    /* height: 400px; */
    position: absolute;
    width: 200px;
    z-index: 2;
 
}

.quotes-famous::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}

.quotes-famous::before {
    left: 0;
    top: 0;
}

.quotes-famous__track {
    animation: scroll 30s linear infinite;
    display: flex;
    gap: 50px;
    width: calc(456px * 4);
}

.quotes-famous__quote {
  
   min-height: 20px;
    width: 400px;
       border: 2px solid cyan;
       border-radius: 29px;
box-shadow:
        inset 0 -3em 3em rgba(0, 0, 0, 0.1),
        0 0 0 2px rgb(255, 255, 255),
        0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
    
}
.quotes-famous__text{
    min-height: 20px;
}
.quotes-famous__text p{
  font-style: italic;
  font-size: 20px;
}
.quotes-famous__text h1{
 
    font-size: 22px;
  }




