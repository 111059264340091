/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Body and Site Styles */
body {
  font-family: Arial, sans-serif;
  background-color: #fefeff;
  color: #000;
  margin: 0;
  padding: 0;
}

.site {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
  width: 100%;
  padding-top: 100px;
}

/* Input and Button Styles */
select.content, input.content {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin: 10px;
  /* font-size: 18px; */
  width: 250px;
  max-width: 100%;
}

button {
  padding: 10px 20px;
  margin: 10px;
  border: none;
  border-radius: 5px;
  background-color: #7082d3;
  color: #fff;
  /* font-size: 18px; */
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #5765a4;
}

/* Table Styles */
.table-container {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow-x: auto;
  padding: 10px;
}

.table {
  width: 98%;
  max-width: 100%;
  table-layout: auto;
  border-collapse: collapse;
  background-color: rgb(242, 246, 247);
  margin: 15px;
  border: 1px solid #000;
}

.table thead {
  background-color: #8496ea;
  color: white;
}

.table thead th {
  background-color: #4358b3;
  padding: 15px;
  text-align: center; 
  border: 1px solid #000; 
}

.table tbody td {
  padding: 10px;
  text-align: center; 
  border: 1px solid #000;
  /* font-size: 18px; */
  font-weight: 500;
  max-width: 150px;
  word-wrap: break-word;
}

.table-striped-columns tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.table-striped-columns tbody tr:nth-child(even) {
  background-color: #ffffff;
}

/* Button within the table */
.btn2 button {
  padding: 5px 10px;
  background-color: #28a745;
  width: 90%;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.btn2 button:hover {
  background-color: #218838;
}

/* Pagination Styles */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.btn4 {
  padding: 8px 16px;
  margin: 0 5px;
  border-radius: 5px;
  background-color: #7082d3;
  color: white;
  cursor: pointer;
  border: none;
}

.btn4.active {
  background-color: #324596;
  color: #fff;
}

/* Responsive Design */
@media (max-width: 768px) {
  .site {
    flex-direction: column;
  }
.table {
    max-width: 100%;
    table-layout: fixed;
  }
  .table thead {
    display: none;
  }

  .table tbody tr {
    display: block;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 10px;
  }

  .table tbody td {
    display: block;
    text-align: right;
    padding: 10px;
    /* font-size: 20px; */
    font-weight: bold;
  }

  .table tbody td::before {
    content: attr(data-label);
    float: left;
    text-transform: uppercase;
    /* font-weight: bold; */
    padding-right: 10px;
  }

  /* Adjusting button and input widths for mobile */
  select.content, input.content {
    width: 100%;
    max-width: 100%;
  }

  button {
    width: 100%;
    /* font-size: 14px; */
  }

  .btn1, .btn3 {
    width: 100%;
  }
}
