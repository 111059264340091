.cv-section {
  position: relative;
  width: 100%;
  margin: 0 auto;
  top: 80px;
  bottom: 100px;
  width: 100%;
  background-color: #4c57d2;
  color: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 10px;
  width: 650px;
  text-align: center;
}

h2 {
  text-align: center;
  color: hsl(0, 0%, 100%);
}

.cv-form .form-group {
  /* background-color: #f4f4f9; */
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cv-form .form-group input[type="text"],
.cv-form .form-group input[type="email"],
.cv-form .form-group input[type="tel"],
.cv-form .form-group input[type="file"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.cv-form .form-group input[type="file"] {
  padding: 5px;
}

.cv-form .submit-btn {
  width: 100%;
  margin-top: 20px;
  padding: 12px;
  background-color: #00d5ff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.cv-form .submit-btn:hover {
  background-color: #2e78b0;
}

@media (max-width: 768px) {
  .cv-section {
    padding: 15px;
    width: 90%;
    padding-top: 90px; 
  }

  .cv-form .form-group input[type="text"],
  .cv-form .form-group input[type="email"],
  .cv-form .form-group input[type="tel"],
  .cv-form .form-group input[type="file"] {
    padding: 8px;
    font-size: 14px;
  }

  .cv-form .submit-btn {
    padding: 10px;
    font-size: 14px;
  }

  h2 {
    font-size: 20px;
    margin-bottom: 15px;
  }
}

@media (max-width: 480px) {
  .cv-section {
    padding: 10px;
    width: 100%;
    padding-top: 100px; 
  }

  .cv-form .form-group input[type="text"],
  .cv-form .form-group input[type="email"],
  .cv-form .form-group input[type="tel"],
  .cv-form .form-group input[type="file"] {
    padding: 6px;
    font-size: 13px;
  }

  .cv-form .submit-btn {
    padding: 8px;
    font-size: 13px;
  }

  h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
